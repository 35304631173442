<template>
  <div class="home" :class="{lan_En : lan != 'zh', lan_Ru: lan == 'ru'}">
    <div class="content1">
      <div class="content1_bg1" >
        <img src="../assets/ball.gif" alt="">
      </div>
      <div class="introduce">
        <p class="text1 delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">{{ $t('home.cont1.text1') }}</p>
        <p class="text2 gradient_text delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">{{ $t('home.cont1.text2') }}</p>
        <p class="text2 gradient_text delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">{{ $t('home.cont1.text2_1') }}</p>
      </div>
      <div class="line delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}"></div>
      <div class="detailed delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">{{ $t('home.cont1.text3') }}</div>
      <div class="detailed delay800 animateHide" v-animate="{delay: 800, class:'fadeInUp'}">{{ $t('home.cont1.text4') }}</div>
      <div class="detailed delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">{{ $t('home.cont1.text5') }}</div>
    </div>
    <div class="content2">
      <el-carousel class="carousel" :interval="5000" arrow="always" height="680px">
        <!-- <el-carousel-item>
          <div class="sliper">
            <div class="sliper_left">
              <p class="sliper_text">{{ $t('home.cont2.title4') }}</p>
              <p class="sliper_text1">{{ $t('home.cont2.title4_1') }}</p>
              <div class="sliper_btn" @click="linkHerf('https://www.deeplink.cloud/public-sale/')">{{ $t('home.cont2.text4') }}</div>
            </div>
            <div class="sliper_right"><img src="../assets/home/comp4.png" alt=""></div>
          </div>
        </el-carousel-item> -->
        <el-carousel-item>
          <div class="sliper">
            <div class="sliper_left">
              <p class="sliper_text">{{ $t('home.cont2.title1') }}</p>
              <div class="sliper_btn" @click="seeVideo(1)">{{ $t('home.cont2.text1') }}</div>
            </div>
            <div class="sliper_right"><img src="../assets/home/comp3.jpg" alt=""></div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="sliper">
            <div class="sliper_left">
              <p class="sliper_text">{{ $t('home.cont2.title2') }}</p>
              <div class="sliper_btn" @click="linkHerf('https://galaxyrace.deepbrainchain.org/')">{{ $t('home.cont2.text2') }}</div>
            </div>
            <div class="sliper_right"><img class="marginL" src="../assets/home/comp1.png" alt=""></div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="sliper">
            <div class="sliper_left">
              <p class="sliper_text">{{ $t('home.cont2.title3') }}</p>
              <div class="sliper_btn" @click="seeVideo(2)">{{ $t('home.cont2.text3') }}</div>
            </div>
            <div class="sliper_right"><img src="../assets/home/comp2.png" alt=""></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content3">
      <div class="cont_title">{{ $t('home.cont3.title') }}</div>
      <div class="cont_desc">{{ $t('home.cont3.text') }}</div>
      <div class="ecologyList">
        <div class="ecologyLi delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../assets/home/cont3_img1.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title1') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text1') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.deeplink.cloud/"><span><img src="../assets/home/deeplink.png" alt=""></span> {{ $t('home.cont3.btn1_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../assets/home/decentral_gpt.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title2') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text2') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.decentralgpt.org/"><span><img src="../assets/home/decentral_gpt.png" alt=""></span> {{ $t('home.cont3.btn2_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../assets/home/superimage.webp" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title3') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text3') }}</div>
          <div class="ecology_btns">
            <!-- <div class="btn1">{{ $t('home.cont3.btn3_1') }}</div> -->
            <a class="btn1" target="_blank" href="https://www.superimage.ai/"><span><img src="../assets/home/superimage.webp" alt=""></span> {{ $t('home.cont3.btn3_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../assets/home/drcpad.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title4') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text4') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.drcpad.io/"><span><img src="../assets/home/drcpad.png" alt=""></span> {{ $t('home.cont3.btn4_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../assets/home/cont3_img5.jpg" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title5') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text5') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.hycons.cloud"><span><img src="../assets/home/hycons.png" alt=""></span> {{ $t('home.cont3.btn5_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../assets/home/subscan.webp" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title6') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text6') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://dbc.subscan.io/"><span><img src="../assets/home/subscan.webp" alt=""></span> {{ $t('home.cont3.btn6_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../assets/home/dbcwallet.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title7') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text7') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://test.dbcscan.io/"><span><img src="../assets/home/dbcwallet.png" alt=""></span> {{ $t('home.cont3.btn7_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../assets/home/cont3_img9.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title9') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text9') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://xaiagent.io/"><span><img src="../assets/home/cont3_img9.png" alt=""></span> {{ $t('home.cont3.btn9_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../assets/home/dbcwallet.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title8') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text8') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.dbcwallet.io/"><span><img src="../assets/home/dbcwallet.png" alt=""></span> {{ $t('home.cont3.btn8_1') }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="unifycontent" @wheel="handleScroll">
      <div class="bg1"><img src="../assets/home/cont4_bg1.jpg" alt=""></div>
      <div class="bg2"><img src="../assets/home/cont4_bg2.jpg" alt=""></div>
      <div class="bg3"><img src="../assets/home/cont4_bg3.png" alt=""></div>
      <div class="bg4"><img src="../assets/home/cont4_bg3.png" alt=""></div>
      <div class="bg5"><img src="../assets/home/cont4_bg1.jpg" alt=""></div>
      <div class="content4">
        <div class="cont_title">{{ $t('home.cont4.title') }}</div>
        <div class="cont_desc">{{ $t('home.cont4.text') }}</div>
        <div class="numberList">
          <div class="number_block w230">
            <!-- <div class="number num1">{{ Number_inc.count1 }}</div> -->
            <div class="number num1">
              <count-to ref='count_start1' :startVal='0' :endVal='Number_inc.count1' :duration='3000'></count-to>
            </div>
            <div class="num_desc">{{ $t('home.cont4.text1') }}</div>
          </div>
          <div class="number_block w230">
            <!-- <div class="number num2">{{ Number_inc.count2 }}%</div> -->
            <div class="number num2">
              <count-to ref='count_start2' :decimals="2" decimal="." :startVal='0' :endVal='Number_inc.count2' :duration='3000' suffix="%"></count-to>
            </div>
            <div class="num_desc">{{ $t('home.cont4.text2') }}</div>
          </div>
          <div class="number_block w390">
            <!-- <div class="number num3">{{ Number_inc.count3.toLocaleString() }}</div> -->
            <div class="number num3">
              <count-to ref='count_start3' :startVal='0' :endVal='Number_inc.count3' :duration='3000'></count-to>
            </div>
            <div class="num_desc">{{ $t('home.cont4.text3') }}</div>
          </div>
          <div class="number_block w320">
            <!-- <div class="number num4">{{ Number_inc.count4.toLocaleString() }}</div> -->
            <div class="number num4">
              <count-to ref='count_start4' :startVal='0' :endVal='Number_inc.count4' :duration='3000'></count-to>
            </div>
            <div class="num_desc">{{ $t('home.cont4.text4') }}</div>
          </div>
          <div class="number_block w230">
            <!-- <div class="number num1">{{ Number_inc.count5 }}</div> -->
            <div class="number num1">
              <count-to ref='count_start5' :decimals="4" decimal="." :startVal='0' :endVal='Number_inc.count5' :duration='3000' suffix="B"></count-to>
            </div>
            <div class="num_desc">{{ $t('home.cont4.text5') }}</div>
          </div>
          <div class="number_block w230">
            <!-- <div class="number num2">{{ Number_inc.count6 }}</div> -->
            <div class="number num2">
              <count-to ref='count_start6' :startVal='0' :endVal='Number_inc.count6' :duration='3000'></count-to>
            </div>
            <div class="num_desc">{{ $t('home.cont4.text6') }}</div>
          </div>
          <div class="number_block w390">
            <!-- <div class="number num3">{{ Number_inc.count7.toLocaleString() }}</div> -->
            <div class="number num3">
              <count-to ref='count_start7' :decimals="4" decimal="." :startVal='0' :endVal='Number_inc.count7' :duration='3000' :suffix="Number_inc.count7_unit"></count-to>
            </div>
            <div class="num_desc">{{ $t('home.cont4.text7') }}</div>
          </div>
          <div class="number_block w320">
            <!-- <div class="number num4">{{ Number_inc.count8.toLocaleString() }}</div> -->
            <div class="number num4">
              <count-to ref='count_start8' :decimals="4" decimal="." :startVal='0' :endVal='Number_inc.count8' :duration='3000' :suffix="Number_inc.count8_unit"></count-to>
            </div>
            <div class="num_desc">{{ $t('home.cont4.text8') }}</div>
          </div>
        </div>
      </div>
      <div class="content5">
        <div class="left">
          <p class="title">{{ $t('home.cont5.title') }}</p>
          <!-- <p class="text">{{ $t('home.cont5.text') }}</p> -->
          <p></p>
        </div>
        <div class="right">
          <div class="list">
            <div class="listInfo delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
              <div class="text1"><span class="line color3"></span>{{ $t('home.cont5.block1.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block1.text') }}</div>
            </div>
            <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <div class="text1"><span class="line color2"></span>{{ $t('home.cont5.block2.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block2.text') }}</div>
            </div>
            <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
              <div class="text1"><span class="line color1"></span>{{ $t('home.cont5.block3.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block3.text') }}</div>
            </div>
          </div>
          <div class="list">
            <div class="listInfo delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
              <div class="text1"><span class="line color6"></span>{{ $t('home.cont5.block4.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block4.text') }}</div>
            </div>
            <div class="listInfo delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
              <div class="text1"><span class="line color5"></span>{{ $t('home.cont5.block5.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block5.text') }}</div>
              <div class="text2">{{ $t('home.cont5.block5.text1') }}</div>
            </div>
            <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <div class="text1"><span class="line color4"></span>{{ $t('home.cont5.block6.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block6.text') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content6">
        <div class="cont_title">{{ $t('home.cont6.title') }}</div>
        <div class="token_list">
          <div class="token_li">
            <div class="token_block height1">
              <div class="token_icon1"></div>
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block1.text') }}</p></div>
            </div>
            <div class="token_block height2">
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block2.text') }}</p></div>
              <div class="token_text">
                <span class="icon"></span>
                <p>{{ $t('home.cont6.block2.text1') }}</p>
              </div>
            </div>
          </div>
          <div class="token_li">
            <div class="token_block height3">
              <div class="token_text"><span class="icon"></span>
                <p>{{ $t('home.cont6.block3.text') }}</p>
              </div>
              <div class="token_text"><span class="icon"></span>
                <p>{{ $t('home.cont6.block3.text1') }}</p>
              </div>
            </div>
            <div class="token_block height4">
              <div class="token_icon2"></div>
              <div class="token_text"><span class="icon"></span>
                <p>{{ $t('home.cont6.block4.text', {stakeNum: totalNum.Stake.toLocaleString(), ratio: totalNum.Stake_ratio}) }}</p>
              </div>
            </div>
          </div>
          <div class="token_li">
            <div class="token_block height5">
              <div class="token_text"><span class="icon"></span>
                <p>{{ $t('home.cont6.block5.text', {stakeNum: Number_inc.count3.toLocaleString(), ratio: totalNum.Issuance_ratio}) }}</p>
              </div>
            </div>
            <div class="token_block height6">
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text') }}</p></div>
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text1') }}</p></div>
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text2') }}</p></div>
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text3') }}</p></div>
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text4') }}</p></div>
            </div>
          </div>
        </div>
      </div>
      <div class="content7">
        <div class="cont_title">{{ $t('home.cont7.title') }}</div>
        <div class="exchange">
          <a target="_blank" href="https://www.gate.tv/trade/DBC_USDT"><img src="../assets/home/gate.png" alt=""></a>
          <!-- <a target="_blank" href="https://www.htx.com/en-us/trade/dbc_usdt?type=spot"><img src="../assets/home/huobi.png" alt=""></a> -->
          <a target="_blank" href="https://swap.swft.pro/#/"><img src="../assets/home/swft.png" alt=""></a>
          <a target="_blank" href="https://app.uniswap.org/swap?outputCurrency=0x1F735f84b07cc20E9aC471C291a87b5A2428d518&chain=ethereum"><img src="../assets/home/uniswap.png" alt=""></a>
          <a target="_blank" href="https://www.bitmart.com/trade/en-US?layout=pro&theme=dark&symbol=DBC_USDT"><img src="../assets/home/bitmart.png" alt=""></a>
          <a target="_blank" href="https://www.mexc.com/exchange/DBC_USDT?_from=search_spot_trade"><img class="mexc" src="../assets/home/mexc.svg" alt=""></a>
        </div>
        <div class="contract_add">{{ $t('home.cont7.text1') }}</div>
        <div class="contract_address">{{ $t('home.cont7.text2') }}</div>
      </div>
      <div class="content8">
        <div class="cont_title">{{ $t('home.cont8.title') }}</div>
        <div class="cont_desc">{{ $t('home.cont8.text1') }}</div>
        <div class="cont_table">
          <table class="table_cont">
            <tr class="theader">
              <th class="width210" colspan="2">{{ $t('home.cont8.table.tr1') }}</th>
              <th class="color1">{{ $t('home.cont8.table.tr2') }}</th>
              <th class="color1">{{ $t('home.cont8.table.tr3') }}</th>
              <th class="color2">{{ $t('home.cont8.table.tr4') }}</th>
              <th class="color2">{{ $t('home.cont8.table.tr5') }}</th>
              <th class="color2">{{ $t('home.cont8.table.tr6') }}</th>
              <th class="width240">{{ $t('home.cont8.table.tr7') }}</th>
            </tr>
            <tr>
              <td colspan="2">{{ $t('home.cont8.table.tbody1.text1') }}</td>
              <td class="color1">15%</td>
              <td class="color1">15%</td>
              <td v-show="lan == 'zh'" class="color2">15</td>
              <td v-show="lan != 'zh'" class="color2">1.5</td>
              <td v-show="lan == 'zh'" class="color2">15</td>
              <td v-show="lan != 'zh'" class="color2">1.5</td>
              <td class="color2">-</td>
              <td class="font16">{{ $t('home.cont8.table.tbody1.text2') }}</td>
            </tr>
            <tr>
              <td class="width60" rowspan="2" style="width: 20px;">{{ $t('home.cont8.table.tbody2.text') }}</td>
              <td>{{ $t('home.cont8.table.tbody2.text1') }}</td>
              <td class="color1">17.35%</td>
              <td class="color1" rowspan="2">25%</td>
              <td v-show="lan == 'zh'" class="color2">17.35</td>
              <td v-show="lan == 'zh'" class="color2">11.47</td>
              <td v-show="lan == 'zh'" class="color2">5.88</td>
              
              <td v-show="lan != 'zh'" class="color2">1.735</td>
              <td v-show="lan != 'zh'" class="color2">1.147</td>
              <td v-show="lan != 'zh'" class="color2">0.588</td>
              <td class="font16">{{ $t('home.cont8.table.tbody2.text2') }}</td>
            </tr>
            <tr>
              <td>{{ $t('home.cont8.table.tbody3.text1') }}</td>
              <td class="color1">7.65%</td>
              <!-- <td class="color1">25%</td> -->
              <td v-show="lan == 'zh'" class="color2">7.65</td>
              <td v-show="lan == 'zh'" class="color2">1.02</td>
              <td v-show="lan == 'zh'" class="color2">6.63</td>

              <td v-show="lan != 'zh'" class="color2">0.765</td>
              <td v-show="lan != 'zh'" class="color2">0.102</td>
              <td v-show="lan != 'zh'" class="color2">0.663</td>
              <td class="font16">{{ $t('home.cont8.table.tbody3.text2') }}</td>
            </tr>

            <tr>
              <td colspan="2">{{ $t('home.cont8.table.tbody4.text1') }}</td>
              <td class="color1">10%</td>
              <td class="color1">10%</td>
              <td v-show="lan == 'zh'" class="color2">10</td>
              <td v-show="lan == 'zh'" class="color2">7</td>
              <td v-show="lan == 'zh'" class="color2">3</td>

              <td v-show="lan != 'zh'" class="color2">1</td>
              <td v-show="lan != 'zh'" class="color2">0.7</td>
              <td v-show="lan != 'zh'" class="color2">0.3</td>
              <td class="font16">{{ $t('home.cont8.table.tbody4.text2') }}</td>
            </tr>
            <tr>
              <td colspan="2">{{ $t('home.cont8.table.tbody5.text1') }}</td>
              <td class="color1">10%</td>
              <td class="color1">10%</td>
              <td v-show="lan == 'zh'" class="color2">10</td>
              <td v-show="lan == 'zh'" class="color2">10</td>
              <td v-show="lan != 'zh'" class="color2">1</td>
              <td v-show="lan != 'zh'" class="color2">1</td>
              <td class="color2">-</td>
              <td class="font16">{{ $t('home.cont8.table.tbody5.text2') }}</td>
            </tr>
            <tr>
              <td class="width60" rowspan="2" style="width: 20px;">{{ $t('home.cont8.table.tbody6.text') }}</td>
              <td>{{ $t('home.cont8.table.tbody6.text1') }}</td>
              <td class="color1">8%</td>
              <td class="color1" rowspan="2">40%</td>
              <td v-show="lan == 'zh'" class="color2">8</td>
              <td v-show="lan == 'zh'" class="color2">2</td>
              <td v-show="lan == 'zh'" class="color2">6</td>
              <td v-show="lan != 'zh'" class="color2">0.8</td>
              <td v-show="lan != 'zh'" class="color2">0.2</td>
              <td v-show="lan != 'zh'" class="color2">0.6</td>
              <td class="font16">{{ $t('home.cont8.table.tbody6.text2') }}</td>
            </tr>
            <tr>
              <td>{{ $t('home.cont8.table.tbody7.text1') }}</td>
              <td class="color1">32%</td>
              <!-- <td class="color1">40%</td> -->
              <td v-show="lan == 'zh'" class="color2">32</td>
              <td v-show="lan == 'zh'" class="color2">8</td>
              <td v-show="lan == 'zh'" class="color2">24</td>
              <td v-show="lan != 'zh'" class="color2">3.2</td>
              <td v-show="lan != 'zh'" class="color2">0.8</td>
              <td v-show="lan != 'zh'" class="color2">2.4</td>
              <td class="font16">{{ $t('home.cont8.table.tbody7.text2') }}</td>
            </tr>
            <tr>
              <td colspan="2">{{ $t('home.cont8.table.tbody8.text1') }}</td>
              <td class="color1" colspan="2" style="text-align: center;">100%</td>
              <!-- <td class="color1">15%</td> -->
              <td v-show="lan == 'zh'" class="color2">100</td>
              <td v-show="lan == 'zh'" class="color2">54.48</td>
              <td v-show="lan == 'zh'" class="color2">45.52</td>
              <td v-show="lan != 'zh'" class="color2">10</td>
              <td v-show="lan != 'zh'" class="color2">5.448</td>
              <td v-show="lan != 'zh'" class="color2">4.552</td>
              <td class="font16"></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="content9">
        <div class="text1">{{ $t('home.cont9.title') }}</div>
        <div class="partner">
          <img src="../assets/investor.png" alt="">
          <img src="../assets/investor1.png" alt="">
          <img src="../assets/investor2.png" alt="">
          <img src="../assets/investor3.png" alt="">
          <img src="../assets/investor4.png" alt="">
          <!-- <img src="../assets/investor5.png" alt=""> -->
          <img src="../assets/investor6.png" alt="">
          <img src="../assets/investor7.png" alt="">
          <img src="../assets/investor8.png" alt="">
          <img src="../assets/investor9.png" alt="">
          <img src="../assets/investor10.png" alt="">
        </div>
      </div>
      <div class="content10">
        <div class="cont_title">{{ $t('home.cont10.title') }}</div>
          <div class="timeLine">
            <!-- <el-scrollbar max-height="460px"> -->
            <div class="time_cont delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
              <div class="time_blank"></div>
              <div class="time_num">
                <p>01</p>
                <div class="time_line"></div>
              </div>
              <div class="time_content">
                <div class="time_cont_title">{{ $t('historyDBC.title1') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text1_1') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text1_2') }}</div>
              </div>
            </div>
            <div class="time_cont text_right delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
              <div class="time_content">
                <div class="time_cont_title">{{ $t('historyDBC.title2') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text2_1') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text2_2') }}</div>
                <div class="time_cont_text">(<a href="https://github.com/DeepBrainChain">https://github.com/DeepBrainChain</a>)</div>
              </div>
              <div class="time_num">
                <p>02</p>
                <div class="time_line"></div>
              </div>
              <div class="time_blank"></div>
            </div>
            <div class="time_cont delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <div class="time_blank"></div>
              <div class="time_num">
                <p>03</p>
                <div class="time_line"></div>
              </div>
              <div class="time_content">
                <div class="time_cont_title">{{ $t('historyDBC.title3') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text3_1') }}</div>
              </div>
            </div>
            <div class="time_cont text_right delay400 animateHide" v-animate="{delay: 400, class:'fadeInUp'}">
              <div class="time_content">
                <div class="time_cont_title">{{ $t('historyDBC.title4') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text4_1') }}</div>
                <div class="time_cont_text">(<a href="https://www.dbcwallet.io">https://www.dbcwallet.io</a>)</div>
                <div class="time_cont_text">{{ $t('historyDBC.text4_2') }}</div>
                <div class="time_cont_text">(<a href="https://dbc.subscan.io">https://dbc.subscan.io</a>)</div>
                <div class="time_cont_text">{{ $t('historyDBC.text4_3') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text4_4') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text4_5') }}</div>
                <div class="time_cont_text">(<a href="https://galaxyrace.deepbrainchain.org">https://galaxyrace.deepbrainchain.org</a>)</div>
                <div class="time_cont_text">{{ $t('historyDBC.text4_6') }}</div>
              </div>
              <div class="time_num">
                <p>04</p>
                <div class="time_line"></div>
              </div>
              <div class="time_blank"></div>
            </div>
            <div class="time_cont delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
              <div class="time_blank"></div>
              <div class="time_num">
                <p>05</p>
                <div class="time_line"></div>
              </div>
              <div class="time_content">
                <div class="time_cont_title">{{ $t('historyDBC.title5') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text5_1') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text5_2') }}</div>
                <div class="time_cont_text">(<a href="https://hibaocloud.com" target="_blank">https://hibaocloud.com</a>)</div>
                <div class="time_cont_text">{{ $t('historyDBC.text5_3') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text5_4') }}</div>
                <div class="time_cont_text">(<a href="https://www.hycons.cloud" target="_blank">https://www.hycons.cloud</a>)</div>
                <div class="time_cont_text">{{ $t('historyDBC.text5_5') }}</div>
                <div class="time_cont_text">(<a href="https://www.deeplink.cloud" target="_blank">https://www.deeplink.cloud</a>)</div>
              </div>
            </div>
            <div class="time_cont text_right delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
              <div class="time_content">
                <div class="time_cont_title">{{ $t('historyDBC.title6') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text6_1') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text6_2') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text6_3') }}</div>
              </div>
              <div class="time_num">
                <p>06</p>
                <div class="time_line"></div>
              </div>
              <div class="time_blank"></div>
            </div>
            <div class="time_cont delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">
              <div class="time_blank"></div>
              <div class="time_num">
                <p>07</p>
                <div class="time_line"></div>
              </div>
              <div class="time_content">
                <div class="time_cont_title">{{ $t('historyDBC.title7') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_1') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_2') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_3') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_4') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_5') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_6') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_7') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_8') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_9') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_10') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_11') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_12') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_13') }}</div>
                <div class="time_cont_text">{{ $t('historyDBC.text7_14') }}</div>
              </div>
            </div>
          <!-- </el-scrollbar> -->
        </div>
      </div>
      <div class="content11">
        <ComponentWave></ComponentWave>
      </div>
      <div class="content12">
        <div class="cont_title">{{ $t('home.cont12.title') }}</div>
        <div class="cont12_list">
          <div class="cont12_li1">
            <div class="cont12_li1_block1">
              <p class="news_title">BlockBeats</p>
              <a target="_blank" href="https://www.theblockbeats.info/news/56089" class="new_text new_font24">ChatGPT两周年，去中心化AI的机遇何在？深脑链DBC给出了答案</a>
              <div class="new_date">
                <p>24-12-08</p>
              </div>
            </div>
            <div class="cont12_li1_block2"><img src="../assets/home/new10.webp" alt=""></div>
          </div>

          <!-- <div class="cont12_li1">
            <div class="cont12_li1_block1">
              <p class="news_title">HackerNoon</p>
              <a target="_blank" href="https://hackernoon.com/the-synergy-of-ai-and-blockchain-how-deepbrain-chain-globally-democratizes-gpu-ai-infrastructure" class="new_text new_font24">The Synergy of AI and Blockchain: How DeepBrain Chain Globally Democratizes GPU AI Infrastructure</a>
              <div class="new_date">
                <p>August 13th, 2024</p>
              </div>
            </div>
            <div class="cont12_li1_block2"><img src="../assets/home/new9.avif" alt=""></div>
          </div> -->
          <div class="cont12_li1">
            <div class="cont12_li1_block3"><img src="../assets/home/new9.avif" alt=""></div>
            <div class="cont12_li1_block4">
              <p class="news_title">HackerNoon</p>
              <a target="_blank" href="https://hackernoon.com/the-synergy-of-ai-and-blockchain-how-deepbrain-chain-globally-democratizes-gpu-ai-infrastructure" class="new_text new_font24">The Synergy of AI and Blockchain: How DeepBrain Chain Globally Democratizes GPU AI Infrastructure</a>
              <div class="new_date">
                <p>August 13th, 2024</p>
              </div>
            </div>
            <div class="cont12_li1_block3"><img src="../assets/home/cont12_img1.png" alt=""></div>
            <div class="cont12_li1_block4">
              <p class="news_title">Yahoo</p>
              <a target="_blank" href="https://finance.yahoo.com/news/deepbrain-chain-leads-charge-embracing-163500805.html" class="new_text new_font24">DeepBrain Chain Leads the Charge in Embracing the DePIN Revolution with Rising AI and Web3 Integration Demand</a>
              <div class="new_date">
                <p>Sat, Mar 30, 2024</p>
              </div>
            </div>
            <!-- <div class="cont12_li1_block3"><img src="../assets/home/new8.jpg" alt=""></div>
            <div class="cont12_li1_block4">
              <p class="news_title">Chain Catcher</p>
              <a target="_blank" href="https://www.chaincatcher.com/article/2117966" class="new_text new_font24">AI 与 Web3 在元宇宙风云际会，DePIN 准备好了吗？</a>
              <div class="new_date">
                <p>2024年03月21日</p>
              </div>
            </div> -->
          </div>
          <div class="cont12_li2">
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/new8.jpg" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">Chain Catcher</p>
                <a target="_blank" href="https://www.chaincatcher.com/article/2117966" class="new_text new_font24">AI 与 Web3 在元宇宙风云际会，DePIN 准备好了吗？</a>
                <div class="new_date">
                  <p>2024年03月21日</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/new5.jpg" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">InvestorsObserver</p>
                <a target="_blank" href="https://www.investorsobserver.com/news/crypto-update/should-you-buy-deepbrain-chain-dbc-saturday" class="new_text new_font24">Should You Buy DeepBrain Chain (DBC) Saturday?</a>
                <div class="new_date">
                  <p>January 27, 2024</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/new7.jpg" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">OvenAdd</p>
                <a target="_blank" href="https://ovenadd.com/deepbrain-chain-dbc-price-prediction/" class="new_text new_font20">DeepBrain Chain (DBC) Price Prediction 2024, 2025, 2030, 2035 | Will DBC Rise?</a>
                <div class="new_date">
                  <p>December 2, 2023</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/cont12_img1.png" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">Brave NewCoin</p>
                <a target="_blank" href="https://bravenewcoin.com/insights/the-top-10-ai-cryptocurrencies" class="new_text new_font20">The Top 10 AI Cryptocurrencies: Up 157% in 2023</a>
                <div class="new_date">
                  <p>21 Sep 2023</p>
                </div>
              </div>
            </div>
            <!-- <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/new4.jpg" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">BlockChain Today</p>
                <a target="_blank" href="https://blog.naver.com/blockchaintoday/223153153609" class="new_text new_font20">DeepBrain Chain, GPU 클라우드 컴퓨팅으로 '클라우드 컴퓨팅 혁신' 실현 [출처] DeepBrain Chain, GPU 클라우드 컴퓨팅으로 '클라우드 컴퓨팅 혁신' 실현|작성자 블록체인투데이</a>
                <div class="new_date">
                  <p>2023. 7. 11</p>
                </div>
              </div>
            </div> -->
          </div>
          <div class="cont12_li3">
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/new4.jpg" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">BlockChain Today</p>
                <a target="_blank" href="https://blog.naver.com/blockchaintoday/223153153609" class="new_text new_font20">DeepBrain Chain, GPU 클라우드 컴퓨팅으로 '클라우드 컴퓨팅 혁신' 실현 [출처] DeepBrain Chain, GPU 클라우드 컴퓨팅으로 '클라우드 컴퓨팅 혁신' 실현|작성자 블록체인투데이</a>
                <div class="new_date">
                  <p>2023. 7. 11</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/cont12_img1.png" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">Altcoin Buzz</p>
                <a target="_blank" href="https://www.altcoinbuzz.io/reviews/top-3-most-profitable-ai-coins-according-to-chatgpt/" class="new_text new_font24">Top 3 Most Profitable AI Coins According to ChatGPT</a>
                <div class="new_date">
                  <p>June 13, 2023</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/new6.png" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">청년투자자 정현</p>
                <a target="_blank" href="https://blog.naver.com/zigyt/223059848282" class="new_text new_font16">인공지능 AI 관련코인 AGIX, FET 가격, 전망 확인해봐요</a>
                <div class="new_date">
                  <p>2023.3.30</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/cont12_img4.png" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">Binance Square</p>
                <a target="_blank" href="https://www.binance.com/en/feed/post/255516" class="new_text new_font16">Huawei Cloud Announces Launch of Metaverse and Web3 Alliance</a>
                <div class="new_date">
                  <p>Feb 27, 2023</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/cont12_img5.png" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">Coin Edition</p>
                <a target="_blank" href="https://coinedition.com/huawei-cloud-and-tencent-cloud-announce-web3-alliances/" class="new_text new_font16">Huawei Cloud and Tencent Cloud Announce Web3 Alliances</a>
                <div class="new_date">
                  <p>Feb 27, 2023</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/cont12_img4.png" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">CoinSpeaker</p>
                <a target="_blank" href="https://www.coinspeaker.com/huawei-cloud-metaverse-web3-blockchain/" class="new_text new_font16">Huawei Cloud Launches Metaverse and Web3 Initiative to Foster Blockchain Adoption in Asian Region</a>
                <div class="new_date">
                  <p>Feb 27, 2023</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/comp3.jpg" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">WeiXin</p>
                <a target="_blank" href="https://mp.weixin.qq.com/s/0qcXdqJGzMMLHgdzYz5z-w" class="new_text new_font16">华为云发起元宇宙 & Web3.0联盟，风口上的 AI 计算平台Deepbrain Chain再次登台亮相</a>
                <div class="new_date">
                  <p>2023年02月25日</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/new2.jpg" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">Cryptotvplus</p>
                <a target="_blank" href="https://cryptotvplus.com/2023/02/all-you-need-to-know-about-deepbrain-chain/" class="new_text new_font16">All you need to know about DeepBrain chain?</a>
                <div class="new_date">
                  <p>February 6, 2023</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/new5.webp" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">Yahoo</p>
                <a target="_blank" href="https://finance.yahoo.com/news/deepbrain-chain-computing-power-mainnet-115100106.html?guccounter=1" class="new_text new_font16">DeepBrain Chain Computing Power Mainnet Launches Online, Meaning All GPU Servers Can Now Freely Connect to the DBC Network, All Information Available On-Chain</a>
                <div class="new_date">
                  <p>December 17, 2021</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/cont12_img7.png" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">AP News</p>
                <a target="_blank" href="https://apnews.com/press-release/marketersmedia/technology-blockchain-artificial-intelligence-5baa7937549a0f02b1cb0612f9afefa5" class="new_text new_font16">DeepBrain Chain computing power mainnet launches online, meaning all GPU servers can now freely connect to the DBC network</a>
                <div class="new_date">
                  <p>December 17, 2021</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/cont12_img3.png" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">36KR</p>
                <a target="_blank" href="https://36kr.com/p/1253772390861320" class="new_text new_font16">一文读懂深脑链DBC：无限扩容的分布式高性能算力网络</a>
                <div class="new_date">
                  <p>2021-06-04</p>
                </div>
              </div>
            </div>
            <div class="cont12_li1_block5">
              <div class="block_img">
                <img src="../assets/home/cont12_img6.png" alt="">
              </div>
              <div class="cont12_li1_block5_cont">
                <p class="news_title">BlockBeats</p>
                <a target="_blank" href="https://www.theblockbeats.info/news/24626?search=1" class="new_text new_font16">高性能计算赛道的深脑链，是Web3.0时代的刚需吗？</a>
                <div class="new_date">
                  <p>2021-06-01</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content13">
        <div class="cont_title">{{ $t('home.cont13.title') }}</div>
        <div class="event_list">

          <div v-for="(item, index) in eventInfo" class="event_li  animateHide" :class="{delay100: item.delay == 100, delay200: item.delay == 200, delay300: item.delay == 300, delay400: item.delay == 400, delay500: item.delay == 500, delay600: item.delay == 600, delay700: item.delay == 700, delay800: item.delay == 800, delay900: item.delay == 900}" v-animate="{delay: item.delay, class:'fadeInUp'}"  v-show='YTBShow ? index < 12 : index < 100'>
            <div class="event_cont">
              <!-- <img :src="getAssetsFile(item.img_url)" alt=""> -->
              <img :src="require(`@/assets/home/${item.img_url}`)" alt="">
            </div>
            <div class="img_cont" @click="linkHerf(item.link)">
              <div class="img_title">{{ item.title }}</div>
              <div class="img_date">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <div v-if="eventInfo.length > 12" class="seeMore" @click="YTBShow = !YTBShow">{{ YTBShow ? $t('showMore') : $t('hideMore') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted, onBeforeUnmount  } from "vue";
  import { CountTo } from 'vue3-count-to';
  import ComponentWave from "@/components/component_wave.vue";
  import { getRewardInfo } from '../api/wss'
  import { getHodoler } from '../api/http'
  import { getTotalIssuance, getTotalstak, getTreasury, getTotalNumber } from '../api/query'
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'Home',
    components: {
      ComponentWave,
      CountTo
    },
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      var Number_inc = ref({
        count1: 0,
        count2: 0,
        count3: 0,
        count4: 0,
        count5: 0,
        count6: 0,
        count7: 0,
        count7_unit: '',
        count8: 0,
        count8_unit: ''
      })
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      const first_entry = ref(true)
      const count_start1 = ref(null)
      const count_start2 = ref(null)
      const count_start3 = ref(null)
      const count_start4 = ref(null)
      const count_start5 = ref(null)
      const count_start6 = ref(null)
      const count_start7 = ref(null)
      const count_start8 = ref(null)
      const doHandleMonth = (month) => {
          var m = month;
          if (month.toString().length == 1) {
              m = "0" + month;
          }
          return m;
      }
      const getDay = (day) => {
          var today = new Date();
          var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
          today.setTime(targetday_milliseconds); //注意，这行是关键代码
          var tYear = today.getFullYear();
          var tMonth = today.getMonth();
          var tDate = today.getDate();
          tMonth = doHandleMonth(tMonth + 1);
          tDate = doHandleMonth(tDate);
          return tYear + "-" + tMonth + "-" + tDate;
      }
      const totalNum = ref({
        Stake: 0,
        Issuance: 0,
        Stake_ratio: 0,
        Issuance_ratio: 0,
      })
      const rewardInfo = ref(null)
      const Total = ref(null)
      const stake_num = ref(null)
      const account = ref(null)
      const holder = ref(null)
      const getNumber = async () => {
        rewardInfo.value = await getRewardInfo();
        Total.value = await getTotalIssuance()
        stake_num.value = await getTotalstak()
        account.value = await getTreasury()
        const total_Number = await getTotalNumber()
        holder.value = await getHodoler({
          "category": "AccountHolderTotal",
          "end": getDay(0),
          "format": "day",
          "start": getDay(-1)
        })
        
        totalNum.value.Stake = Math.floor( total_Number.Stake_data / Math.pow(10, 15))
        totalNum.value.Issuance = Math.floor( total_Number.Issuance_data / Math.pow(10, 15))
        totalNum.value.Stake_ratio = Number(((totalNum.value.Stake / totalNum.value.Issuance )*100).toFixed(2))
        totalNum.value.Issuance_ratio = Number(((Math.floor(rewardInfo.value.totalStake / Math.pow(10, 15)) / totalNum.value.Issuance )*100).toFixed(2))
      }
      const handleScroll = (event) => {
        Number_inc.value.count1 = rewardInfo.value.totalGpuNum
        Number_inc.value.count2 = Number(((rewardInfo.value.totalRentedGpu / rewardInfo.value.totalGpuNum )*100).toFixed(2))
        Number_inc.value.count3 = Math.floor(rewardInfo.value.totalStake / Math.pow(10, 15))
        Number_inc.value.count4 = Math.floor(rewardInfo.value.totalBurnFee / Math.pow(10, 15))
        Number_inc.value.count5 = Number(Total.value&&Total.value.replace(/BDBC/, "").replace(' ', ""))
        Number_inc.value.count6 = Number(holder.value.data.list ? holder.value.data.list[holder.value.data.list.length-1].total : 0)
        if (stake_num.value&&stake_num.value.indexOf('MDBC') > 0) {
          Number_inc.value.count7 = Number(stake_num.value.replace(/MDBC/, "").replace(' ', "")) 
          Number_inc.value.count7_unit = 'M'
        } else if (stake_num.value&&stake_num.value.indexOf('BDBC') > 0) {
          Number_inc.value.count7 = Number(stake_num.value.replace(/BDBC/, "").replace(' ', "") )
          Number_inc.value.count7_unit = 'B'
        } else {
          Number_inc.value.count7 = Number(stake_num.value )
          Number_inc.value.count7_unit = ''
        }
        if (account.value&&account.value.indexOf('MDBC') > 0) {
          Number_inc.value.count8 = Number(account.value.replace(/MDBC/, "").replace(' ', "")) 
          Number_inc.value.count8_unit = 'M'
        } else if (account.value&&account.value.indexOf('BDBC') > 0) {
          Number_inc.value.count8 = Number(account.value.replace(/BDBC/, "").replace(' ', "") )
          Number_inc.value.count8_unit = 'B'
        } else {
          Number_inc.value.count8 = Number(account.value.replace(/DBC/, "").replace(' ', "") )
          Number_inc.value.count8_unit = ''
        }
        if (first_entry.value) {
          count_start1.value.start();
          count_start2.value.start();
          count_start3.value.start();
          count_start4.value.start();
          count_start5.value.start();
          count_start6.value.start();
          count_start7.value.start();
          count_start8.value.start();
          first_entry.value = false
        }
      }
      const ball_show = ref(true)
      const CurrentHeight = ref(0)
      const handleScroll1 = () => {
        CurrentHeight.value = document.body.scrollTop || document.documentElement.scrollTop
        if (CurrentHeight.value < 900) {
          ball_show.value = true
        } else {
          ball_show.value = false
        }
      }

      const visibilityChange = () => {
        if (document.hidden) {
          ball_show.value = false
        } else {
          if (CurrentHeight.value < 700) {
            ball_show.value = true
          } else {
            ball_show.value = false
          }
        }
      }
      const seeVideo = (type) => {
        if (type == 1) {
          window.open('/web3.mp4')
        } else {
          window.open('/DBC_Pitch_Deck.pdf')
        }
      }
      const YTBShow = ref(true)
      const eventInfo = ref([
        {
          title: "DBC participated in the 1012EXPO Big Navigation Blockchain Conference, a standout event focused on future tech trends and innovative collaboration models.",
          time: '17/10 2024',
          link: 'https://x.com/deepbrainfeng/status/1846490905043521916',
          delay: 200,
          img_url: 'cont13_bg30.jpg'
        },
        {
          title: "DeepBrain Chain at the Singapore Token2049. ",
          time: '18/09 2024',
          link: 'https://x.com/DeepBrainChain/status/1836387111114879067',
          delay: 200,
          img_url: 'cont13_bg29.jpg'
        },
        {
          title: "DeepBrainChain and DeepLinkGlobal Signs Strategic Cooperation Agreement with IPCA to Promote the Application of AI and Blockchain Technology in Korean Internet Cafe Industry",
          time: '10/09 2024',
          link: 'https://x.com/DeepBrainChain/status/1833739141290623405',
          delay: 200,
          img_url: 'cont13_bg28.jpg'
        },
        {
          title: "DeepBrain Chain successfully attended and completed the Web X event in Tokyo, Japan.",
          time: '02/09 2024',
          link: 'https://x.com/DeepBrainChain/status/1830534976254746764',
          delay: 300,
          img_url: 'cont13_bg27.jpg'
        },
        {
          title: "DeepBrain Chain (DBC) successfully hosted the “Future of Decentralized AI” summit at Stanford University.",
          time: '28/07 2024',
          link: 'https://x.com/DeepBrainChain/status/1817793793154011213',
          delay: 300,
          img_url: 'cont13_bg26.jpg'
        },
        // {
        //   title: 'DeepLink’s Cornerstone Investor Mr. Adam Ha, participated in the DEPIN ALLIANCE YACHT PARTY ⛵️panel and introduced DeepLink to the Vietnam market.',
        //   time: '07/06 2024',
        //   link: 'https://x.com/DeepLinkGlobal/status/1799036124150821272',
        //   delay: 100,
        //   img_url: 'cont13_bg25.jpg'
        // },
        {
          title: "We held a meetup in Seoul today with ETNEWS, Korea's top IT news media",
          time: '14/06 2024',
          link: 'https://x.com/DeepBrainChain/status/1801600720757789162',
          delay: 200,
          img_url: 'cont13_bg24.jpg'
        },
        {
          title: 'DeepBrainChain attended the US Consensus Conference. We brought the first AI project',
          time: '01/06 2024',
          link: 'https://x.com/DeepBrainChain/status/1796677581502095490',
          delay: 300,
          img_url: 'cont13_bg23.jpg'
        },
        {
          title: "DBC founders and council members attended the BTC Consensus Conference in Austin",
          time: '30/05 2024',
          link: 'https://x.com/DeepBrainChain/status/1795996265492140187',
          delay: 200,
          img_url: 'cont13_bg22.jpg'
        },
        {
          title: 'DeepBrainChain participated in the 2024 Tokyo Blockchain Expo, one of the largest blockchain events in Japan. This exhibition brought together global elites from the AI and Blockchain industries.',
          time: '24/05 2024',
          link: 'https://x.com/DeepBrainChain/status/1793958508335120434',
          delay: 300,
          img_url: 'cont13_bg21.jpg'
        },
        {
          title: 'Introducing DeepBrainChain to crypto investors from multiple countries around the world at the token2049 conference',
          time: '19/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1781270973737816073',
          delay: 400,
          img_url: 'cont13_bg20.jpg'
        },

        {
          title: 'Arriving at the Dubai venue, the DBC&Deeplink booth is packed with people.',
          time: '18/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1780863389889364358',
          delay: 200,
          img_url: 'cont13_bg19.jpg'
        },
        {
          title: 'TeamZ Web3 Japan Summit ended successfully！',
          time: '14/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1779440009134919731',
          delay: 300,
          img_url: 'cont13_bg18.jpg'
        },
        {
          title: 'The DBC booth was crowded with people and was warmly welcomed by everyone.',
          time: '13/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1779152234875584607',
          delay: 400,
          img_url: 'cont13_bg17.jpg'
        },
        {
          title: 'Felix, a DBC director from Japan, is delivering a speech at the Global DePIN Hardware Conference.',
          time: '08/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1777276310571897256',
          delay: 300,
          img_url: 'cont13_bg16.jpg'
        },
        {
          title: 'DBC Ambassadors attended the DePIN China Tour Shenzhen Meetup',
          time: '05/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1776195577954607110',
          delay: 400,
          img_url: 'cont13_bg15.jpg'
        },
        {
          title: 'DBC Ambassador introduces #DeepBrainChain at Web3 Storage Conference hosted by Swarm #DBC #DeepBrainChain',
          time: '03/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1775470361074868698',
          delay: 500,
          img_url: 'cont13_bg14.jpg'
        },
        {
          title: '🔥DeepBrainChain Meetup event is in progress at Amazon Hangzhou, China, Tongji University, ICP, and Llama China',
          time: '30/03 2024',
          link: 'https://x.com/DeepBrainChain/status/1773992128999473261',
          delay: 300,
          img_url: 'cont13_bg13.jpg'
        },
        {
          title: 'Electronic sports Hotel Group covers 27 cities in China and is a well-known esports hotel brand in China. Starting from this year, we will work together to provide them with DBC’s GPU computing power and DeepLink’s cloud esports ultra-low game rendering latency. solution',
          time: '18/03 2024',
          link: 'https://x.com/deepbrainfeng/status/1769573009768288765',
          delay: 400,
          img_url: 'cont13_bg12.jpg'
        },
        {
          title: 'The AI ​​cloud platform Hycons based on the DBC decentralized GPU network provides AI users with an unparalleled experience, and the GPU price saves more than 90% compared to AWS.',
          time: '07/03 2024',
          link: 'https://x.com/deepbrainfeng/status/1765532462351024216',
          delay: 500,
          img_url: 'cont13_bg11.jpg'
        },

        {
          title: 'Chengdu Internet Cafe Technical Conference in China',
          time: '04/03 2024',
          link: 'https://twitter.com/DeepBrainChain/status/1764676037366231365?t=LUmiOO3PWHX5Hca41pbXRw&s=19',
          delay: 500,
          img_url: 'cont13_bg10.jpg'
        },
        {
          title: 'DBC Blockchain Conference Video Review in Phuket, Thailand',
          time: '05/02 2024',
          link: 'https://twitter.com/DeepBrainChain/status/1754500650648715521?t=-pneye4mhfHfdZGjoZ5zXw&s=19',
          delay: 600,
          img_url: 'cont13_bg9.jpg'
        },
        {
          title: 'DeepBrainChain& #DeepLink&TIKEREN cloud gaming all-in-one strategic cooperation conference to jointly enter Japan',
          time: '20/12 2023',
          link: 'https://twitter.com/deepbrainfeng/status/1737496574278725733?t=3HftJUj9ee-3cEkjYnDr1w&s=19',
          delay: 700,
          img_url: 'cont13_bg8.png'
        },
        {
          title: 'Vietnam ESPORTS3.0 EVENT',
          time: '07/10 2023',
          link: 'https://twitter.com/DeepLinkVietNam/status/1710551891061756038',
          delay: 500,
          img_url: 'cont13_bg7.png'
        },
        {
          title: 'Vietnam MARVELS HCMC DeepBrain Chain',
          time: '08/06 2023',
          link: 'https://twitter.com/DeepBrainChain/status/1666651735266562052',
          delay: 600,
          img_url: 'cont13_bg6.png'
        },
        {
          title: 'DeepBrain Chain Hong Kong Events',
          time: '16/04 2023',
          link: 'https://twitter.com/deepbrainfeng/status/1647529443945226241?s=20',
          delay: 700,
          img_url: 'cont13_bg5.png'
        },
        {
          title: 'DeepBrain Chain Singapore Events',
          time: '17/01 2023',
          link: 'https://twitter.com/deepbrainfeng/status/1615370872252137473?s=20',
          delay: 800,
          img_url: 'cont13_bg4.png'
        },
        {
          title: 'DeepBrain Chain AI meets Blockchain in Stuttgart, Germany',
          time: '13/07 2018',
          link: 'https://twitter.com/onegamerocks/status/1017718325969899522',
          delay: 700,
          img_url: 'cont13_bg3.png'
        },
        {
          title: 'DeepBrain Chain participates CCF-GAIR in Shenzhen',
          time: '01/07 2018',
          link: 'https://twitter.com/DeepBrainChain/status/1013332400208670720',
          delay: 800,
          img_url: 'cont13_bg2.png'
        },
        {
          title: 'DeepBrain Chain Blockchain Connect Conference in San Francisco',
          time: '13/01 2018',
          link: 'https://twitter.com/coindar_/status/951993629576454144',
          delay: 900,
          img_url: 'cont13_bg1.jpg'
        }
      ])
      const linkHerf = (str) => {
        window.open(str)
      }

      onMounted(async () => {
        await getNumber()
        window.addEventListener('scroll', handleScroll1)
        window.addEventListener('visibilitychange', visibilityChange)
      });
      onBeforeUnmount(() => {
        ball_show.value = false
        window.removeEventListener('scroll', handleScroll1)
        window.removeEventListener('visibilitychange', visibilityChange)
      });
      return {
        lan,
        seeVideo,
        linkHerf,
        totalNum,
        YTBShow,
        Number_inc,
        count_start1,
        count_start2,
        count_start3,
        count_start4,
        count_start5,
        count_start6,
        count_start7,
        count_start8,
        handleScroll,
        ball_show,
        handleScroll1,
        eventInfo,
      };
    }
  })
</script>

<style lang="scss" scoped>
.home {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  .cont_title {
    color: #fff;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 10px;
    letter-spacing: 0.96px;
  }
  .cont_desc {
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    height: 800px;
    .content1_bg1 {
      position: absolute;
      right: 0;
      top: 90px;
      width: 630px;
      height: 630px;
      display: flex;
      overflow: hidden;
      align-items: center;
      border-radius: 100%;
      justify-content: center;
      img {
        width: 100%;
        height: 630px;
        object-fit: cover;
      }
    }
    .introduce {
      position: relative;
      z-index: 1;
      padding-top: 122px;
      .text1 {
        color: #FFF;
        font-size: 70px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.6px;
      }
      .text2 {
        font-size: 70px;
        letter-spacing: 1.6px;
      }
    }
    .line {
      width: 17px;
      height: 80px;
      margin: 30px 10px;
      background: url(../assets/home/line.png) no-repeat;
      background-size: 9px 80px;
    }
    .detailed {
      width: 560px;
      font-size: 16px;
      color: #899AA9;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1px;
      margin-bottom: 12px;
    }
  }
  .content2 {
    width: 100%;
    height: 680px;
    background: #000;
    .carousel {
      width: 1200px;
      margin: auto;
      .sliper {
        display: flex;
        width: 100%;
        height: 680px;
        padding: 0 60px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        .sliper_left {
          width: 540px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .sliper_text {
            color: #fff;
            font-size: 36px;
            font-weight: 500;
            line-height: normal;
            // margin-bottom: 10px;
            letter-spacing: 0.96px;
          }
          .sliper_text1 {
            color: #fff;
            font-size: 80px;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 10px;
            letter-spacing: 0.96px;
          }
          .sliper_btn {
            // margin: auto;
            color: #FFF;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            margin-top: 60px;
            padding: 10px 60px;
            line-height: normal;
            transition: all .5s;
            text-decoration: none;
            background: #41A4FF;
            margin-right: 50px;
            &:hover {
              background: #0989FF;
            }
          }
        }
        .sliper_right {
          width: 500px;
          img {
            width: 500px;
            &.marginL {
              margin-left: 60px;
            }
          }
        }
      }
    }
  }
  .content3 {
    width: 100%;
    // height: 1010px;
    padding: 80px 0 40px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: 0 -400px;
    background-image: url(../assets/home/cont3_bg.png);
    .ecologyList {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      margin: 100px auto 0;
      justify-content: space-between;
      .ecologyLi {
        width: 384px;
        // height: 270px;
        position: relative;
        margin-bottom: 80px;
        padding: 60px 24px 70px;
        box-sizing: border-box;
        border: 2px solid #FFF;
        backdrop-filter: blur(12.5px);
        transition: all 1s;
        box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        &:hover {
          border: 2px solid ;
          border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
          backdrop-filter: blur(12.5px);
          box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
          background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(148, 148, 148, 0.40) 136.58%);
        }
        .ecologyIcon {
          position: absolute;
          width: 96px;
          height: 96px;
          top: -50px;
          left: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .ecology_title {
          color: #FFF;
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 15px;
          line-height: normal;
        }
        .ecology_text {
          font-size: 16px;
          color: #899AA9;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 15px;
          word-wrap: break-word;
        }
        .ecology_btns {
          position: absolute;
          bottom: 24px;
          width: 336px;
          height: 43px;
          display: flex;
          color: #FFF;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          align-items: center;
          justify-content: space-between;
          .btn {
            width: 160px;
            height: 43px;
            color: #FFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            border: 1px solid #FFF;
            span {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
          .btn1 {
            width: 100%;
            height: 43px;
            display: flex;
            color: #FFF;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            border: 1px solid #FFF;
            span {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
  .unifycontent {
    width: 100%;
    overflow: hidden;
    position: relative;
    .bg1 {
      position: absolute;
      top: 100px;
      margin: auto;
      width: 1920px;
      height: 1690px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1920px;
        height: 1690px;
      }
    }
    .bg2 {
      position: absolute;
      top: 2000px;
      margin: auto;
      width: 1200px;
      height: 1200px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1200px;
        height: 1200px;
      }
    }
    .bg3 {
      position: absolute;
      top: 3450px;
      margin: auto;
      width: 1603px;
      height: 1348px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1603px;
        height: 1348px;
      }
    }
    .bg4 {
      position: absolute;
      top: 6300px;
      z-index: 1;
      margin: auto;
      width: 1603px;
      height: 1348px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1603px;
        height: 1348px;
      }
    }
    .bg5 {
      position: absolute;
      top: 7600px;
      margin: auto;
      width: 1920px;
      height: 1690px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1920px;
        height: 1690px;
      }
    }
    .content4 {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: auto;
      padding: 80px 0 40px;
      box-sizing: border-box;
      .numberList {
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .number_block {
          margin-bottom: 60px;
          &.w390 {
            width: 390px;
          }
          &.w230 {
            width: 230px;
          }
          &.w155 {
            width: 155px;
          }
          &.w320 {
            width: 320px;
          }
          .number {
            font-size: 50px;
            font-weight: 500;
            line-height: 132px;
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &.num1 {
              background-image: linear-gradient(80deg, #9814FF 13.88%, #215DFF 93.31%);
            }
            &.num2 {
              background-image: linear-gradient(77deg, #2053FF 12.51%, #2DC9F8 84.27%);
            }
            &.num3 {
              background-image: linear-gradient(90deg, #30C7FD 0.03%, #00FFA3 108.27%);
            }
            &.num4 {
              background-image: linear-gradient(132deg, #04F9AB -6.93%, #C6FF7D 103.68%);
            }
          }
          .num_desc {
            height: 48px;
            font-size: 16px;
            color: #C4C4C4;
            font-weight: 400;
            line-height: 24px;
          }
        }
        
      }
    }
    .content5 {
      position: relative;
      z-index: 1;
      width: 1200px;
      display: flex;
      margin: 0 auto;
      align-items: center;
      padding: 80px 0 100px;
      box-sizing: border-box;
      justify-content: space-between;
      .left {
        width: 400px;
        margin-right: 100px;
        .title {
          color: #fff;
          font-size: 48px;
          font-weight: 500;
          margin-bottom: 10px;
          line-height: normal;
          letter-spacing: 0.96px;
        }
        .text {
          font-size: 16px;
          color: #899AA9;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 1px;
        }
      }
      .right {
        flex: 1;
        // height: 480px;
        display: flex;
        justify-content: space-between;
        .list {
          width: 340px;
          display: flex;
          flex-direction: column-reverse;
          .listInfo {
            width: 100%;
            padding: 24px;
            margin-top: 15px;
            box-sizing: border-box;
            backdrop-filter: blur(2px);
            background: rgba(0, 0, 0, 0.24);
            .text1 {
              display: flex;
              color: #fff;
              font-size: 24px;
              font-weight: 500;
              line-height: 32px;
              align-items: center;
              margin-bottom: 10px;
              .line {
                width: 2px;
                height: 28px;
                margin-right: 15px;
                &.color3 {
                  background: #9945FF;
                }
                &.color2 {
                  background: #2059FE;
                }
                &.color1 {
                  background: #16C8FF;
                }
                &.color6 {
                  background: #FF5D39;
                }
                &.color5 {
                  background: #FFD512;
                }
                &.color4 {
                  background: #19FB9B;
                }
              }
            }
            .text2 {
              font-size: 16px;
              color: #899AA9;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
    }
    .content6 {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0 100px;
      box-sizing: border-box;
      .token_list {
        width: 100%;
        display: flex;
        margin-top: 65px;
        justify-content: space-between;
        .token_li {
          width: 385px;
          @keyframes glowing {
            0% {
              border-bottom: 2px solid;
              border-image: linear-gradient(to right, #36FFCF, #36FFCF, #36FFCF, #438EFF) 1;
            }
            20% {
              border-bottom: 2px solid;
              border-image: linear-gradient(to right, #438EFF, #36FFCF, #36FFCF, #36FFCF) 1;
            }
            40% {
              border-bottom: 2px solid;
              border-image: linear-gradient(to right, #36FFCF, #438EFF, #36FFCF, #36FFCF) 1;
            }
            60% {
              border-bottom: 2px solid;
              border-image: linear-gradient(to right, #36FFCF, #36FFCF, #438EFF, #36FFCF) 1;
            }
            100% {
              border-bottom: 2px solid;
              border-image: linear-gradient(to right, #36FFCF, #36FFCF, #36FFCF, #438EFF) 1;
            }
          }
          .token_block {
            padding: 24px;
            display: flex;
            margin-bottom: 25px;
            flex-direction: column;
            box-sizing: border-box;
            background: #10132F;
            position: relative;
            border-bottom: 2px solid #36FFCF;
            &:hover {
              animation: glowing 1s infinite;
            }
            .token_text {
              display: flex;
              font-size: 16px;
              color: #899AA9;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 10px;
              justify-content: space-between;
              .icon {
                width: 12px;
                height: 12px;
                margin-top: 6px;
                background: url(../assets/home/cont5_icon.png) 100%/100% no-repeat;
              }
              p {
                width: 310px;
              }
            }
            .token_icon1 {
              width: 200px;
              height: 200px;
              background: url(../assets/home/cont5_img1.png) 100%/100% no-repeat;
            }
            .token_icon2 {
              width: 141px;
              height: 140px;
              background: url(../assets/home/cont5_img2.png) 100%/100% no-repeat;
            }
          }
          .height1 {
            height: 336px;
          }
          .height2 {
            height: 188px;
          }
          .height3 {
            height: 232px;
          }
          .height4 {
            height: 292px;
          }
          .height5 {
            height: 192px;
          }
          .height6 {
            height: 332px;
          }
          
        }
      }
    }
    .content7 {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0 100px;
      box-sizing: border-box;
      .exchange {
        width: 100%;
        display: flex;
        margin: 65px 0;
        align-items: center;
        justify-content: space-around;
        img {
          height: 45px;
          cursor: pointer;
        }
        img.mexc {
          height: 30px;
        }
      }
      .contract_add {
        font-size: 16px;
        font-weight: 400;
        color: #899AA9;
        line-height: 24px;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: 15px;
      }
      .contract_address {
        font-size: 32px;
        font-weight: 400;
        color: transparent;
        text-align: center;
        letter-spacing: 1px;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
      }
    }
    .content8 {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0 100px;
      box-sizing: border-box;
      .cont_table {
        width: 100%;
        padding: 24px;
        margin-top: 40px;
        box-sizing: border-box;
        background: rgba(31, 77, 241, 0.04);
        border: 1px solid rgba(255, 255, 255, 0.08);
        .table_cont {
          width: 100%;
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          line-height: 150%;
          text-align: left;
          border-collapse: collapse;
          tr.theader {
            background: rgba(0, 194, 255, 0.16);
            th {
              border: none;
              padding: 10px;
            }
          }
          th {
            padding: 0 20px;
          }
          td {
            padding: 10px 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }
          .font16 {
            font-size: 16px;
            font-weight: 400;
            color: #899AA9;
            line-height: 150%;
          }
          .color1 {
            color: #38EED6;
          }
          .color2 {
            color: #41A4FF;
          }
          .width60 {
            width: 60px;
            box-sizing: border-box;
          }
          .width210 {
            width: 210px;
          }
          .width240 {
            width: 240px;
          }
        }
      }
    }
    .content9 {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: 0 auto;
      padding: 40px 0 60px;
      box-sizing: border-box;
      .text1 {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        letter-spacing: 1px;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
      }
      .partner {
        width: 100%;
        display: flex;
        margin-top: 40px;
        align-items: center;
        justify-content: space-around;
        img {
          // background: #fff;
          height: 50px;
          cursor: pointer;
        }
      }
    }
    .content10 {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0 0;
      box-sizing: border-box;
      .timeLine {
        width: 100%;
        // height: 456px;
        margin: 40px 0;
        overflow: hidden;
        .time_cont {
          width: 1200px;
          display: flex;
          justify-content: space-between;
          &.text_right {
            text-align: right;
          }
          .time_blank {
            width: 536px;
          }
          .time_content {
            width: 536px;
            display: flex;
            line-height: 150%;
            flex-direction: column;
            .time_cont_title {
              color: #fff;
              font-size: 24px;
              font-weight: 700;
              margin-top: 18px;
            }
            .time_cont_text {
              font-size: 16px;
              color: rgba(255, 255, 255, .7);
              font-weight: 500;
              margin-top: 10px;
              a {
                color: #fff;
              }
            }
          }
          .time_num {
            width: 80px;
            display: flex;
            align-items: center;
            flex-direction: column;
            p {
              color: rgba(255, 255, 255, 0.8);
              font-size: 40px;
              font-weight: 900;
              line-height: 150%; 
            }
            .time_line {
              width: 2px;
              margin-top: 5px;
              height: -webkit-fill-available;
              background: linear-gradient(180deg, #36FFCF -3.33%, #438EFF 100%);
            }
          }
        }
      }
      
    }
    .content11 {
      position: relative;
      width: 100%;
      height: 430px;
      .contbg {
        position: absolute;
        margin: auto;
        width: 1900px;
        height: 430px;
        left: 50%;
        transform: translateX(-50%);
        img {
          width: 1900px;
          height: 430px;
        }
      }
    }
    .content12 {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0 100px;
      box-sizing: border-box;
      .cont12_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 60px;
        justify-content: space-between;
        .cont12_li1 {
          width: 320px;
          height: 780px;
          .cont12_li1_block1 {
            width: 320px;
            height: 260px;
            margin-bottom: 20px;
          }
          .cont12_li1_block2 {
            width: 320px;
            height: 500px;
            display: flex; /* 设置为flex布局 */
            justify-content: center; /* 水平居中对齐 */
            align-items: center; /* 垂直居中对齐 */
            overflow: hidden;
            img {
              height: 500px;
            }
          }
          .cont12_li1_block3 {
            width: 320px;
            height: 210px;
            display: flex; /* 设置为flex布局 */
            justify-content: center; /* 水平居中对齐 */
            align-items: center; /* 垂直居中对齐 */
            overflow: hidden;
            img {
              width: 320px;
              height: 210px;
              object-fit: cover;
            }
          }
          .cont12_li1_block4 {
            margin-top: 16px;
            width: 320px;
            height: 160px;
            .new_text {
              overflow: hidden; /* 超出部分将被隐藏 */
              display: -webkit-box; /* 必需的WebKit前缀 */
              -webkit-line-clamp: 3; /* 限制显示的行数为2行 */
              -webkit-box-orient: vertical; /* 垂直布局 */
            }
          }
        }
        .cont12_li2 {
          width: 500px;
          height: 780px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .cont12_li1_block5 {
            width: 500px;
            height: 176px;
            padding: 15px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: space-between;
            background: rgba(65, 164, 255, 0.16);
            .block_img {
              width: 140px;
              height: 140px;
              overflow: hidden;
              margin-right: 20px;
            }
            img {
              // width: 140px;
              width: 100%;
              height: 140px;
              object-fit: cover;
              margin-right: 20px;
            }
            .cont12_li1_block5_cont {
              flex: 1;
              .new_text {
                overflow: hidden; /* 超出部分将被隐藏 */
                display: -webkit-box; /* 必需的WebKit前缀 */
                -webkit-line-clamp: 3; /* 限制显示的行数为2行 */
                -webkit-box-orient: vertical; /* 垂直布局 */
              }
            }
          }
        }

        
        .cont12_li3 {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .cont12_li1_block5 {
            width: 590px;
            height: 176px;
            padding: 15px;
            display: flex;
            margin-top: 15px;
            align-items: center;
            box-sizing: border-box;
            justify-content: space-between;
            background: rgba(65, 164, 255, 0.16);
            .block_img {
              width: 140px;
              height: 140px;
              overflow: hidden;
              margin-right: 20px;
            }
            img {
              // width: 140px;
              width: 100%;
              height: 140px;
              object-fit: cover;
              margin-right: 20px;
            }
            .cont12_li1_block5_cont {
              flex: 1;
              .new_text {
                overflow: hidden; /* 超出部分将被隐藏 */
                display: -webkit-box; /* 必需的WebKit前缀 */
                -webkit-line-clamp: 3; /* 限制显示的行数为2行 */
                -webkit-box-orient: vertical; /* 垂直布局 */
              }
            }
          }
        }

        .news_title {
          font-size: 14px;
          font-weight: 500;
          color: #41A4FF;
          line-height: normal;
          letter-spacing: 0.28px;
        }
        .new_text {
          color: #fff;
          margin: 15px 0;
          font-weight: 500;
          display: block;
          line-height: normal;
          transition: all 2s;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
          }
        }
        .new_font24 {
          font-size: 24px;
          letter-spacing: 0.48px;
        }
        .new_font20 {
          font-size: 20px;
          letter-spacing: 0.4px;
        }
        .new_font16 {
          font-size: 16px;
          letter-spacing: 0.32px;
        }
        .new_color1 {
          color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
        }
        .new_date {
          display: flex;
          font-size: 16px;
          color: #899AA9;
          font-weight: 400;
          align-items: center;
          line-height: normal;
          letter-spacing: 0.32px;
          justify-content: space-between;
          p {
            display: flex;
            align-items: center;
            .new_icon {
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url(../assets/home/good.png) no-repeat 100%/100%;
            }
          }
        }
      }
    }
    .content13 {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0 100px;
      box-sizing: border-box;
      .event_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 60px;
        justify-content: space-between;
        .event_li {
          width: 370px;
          .event_cont {
            width: 370px;
            height: 280px;
            padding: 8px;
            display: flex;
            overflow: hidden;
            align-items: center;
            margin-bottom: 12px;
            border: 2px solid;
            box-sizing: border-box;
            justify-content: center;
            border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
            img {
              width: 350px;
              height: 260px;
              object-fit: cover;
            }
          }
          .img_cont {
            width: 100%;
            display: flex;
            color: #fff;
            cursor: pointer;
            margin-bottom: 20px;
            flex-direction: column;
            .img_title {
              height: 52px;
              font-size: 20px;
              font-weight: 500;
              line-height: normal;
              margin-bottom: 10px;
              letter-spacing: 0.4px;
              overflow: hidden; /* 超出部分将被隐藏 */
              display: -webkit-box; /* 必需的WebKit前缀 */
              -webkit-line-clamp: 2; /* 限制显示的行数为2行 */
              -webkit-box-orient: vertical; 
            }
            .img_date {
              font-size: 16px;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.32px;
            }
          }
        }
      }
      .seeMore {
        display: table;
        font-size: 18px;
        cursor: pointer;
        font-weight: 500;
        line-height: 20px;
        padding: 10px 50px;
        margin: 20px auto 0;
        border-radius: 20px;
        letter-spacing: 0.08em;
        backdrop-filter: blur(6px);
        color: rgb(255, 255, 255);
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.lan_En.home {
  .content1 {
    .detailed {
      letter-spacing: 0;
    }
  }
  .unifycontent {
    .bg2 {
      top: 2400px;
    }
    .bg4 {
      top: unset;
      bottom: 2400px;
    }
    .bg5 {
      top: unset;
      bottom: 800px;
    }
    .content6 {
      .token_list {
        .token_li {
          .height1 {
            height: 405px;
          }
          .height2 {
            height: 250px;
          }
          .height3 {
            height: 363px;
          }
          .height5 {
            height: 270px;
          }
          .height6 {
            height: 385px;
          }
        }
      }
    }
    .content8 {
      .cont_table {
        .table_cont {
          font-size: 14px;
          .width210 {
            width: 120px;
          }
          .width240 {
            width: 400px;
          }
        }
      }
    }
  }
}

.lan_Ru.home {
  .unifycontent {
    .content6 {
      .token_list {
        .token_li {
          .token_block {
            .token_text {
              font-size: 13px;
            }
          }
        }
      }
    }
    .content8 {
      .cont_table  {
        .table_cont {
          font-size: 14px;
        }
      }
    }
  }
}

</style>
