<template>
  <div class="buyGpuServer" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('buyGpuServer.title') }}</div>
      <div class="cont_desc">{{ $t('buyGpuServer.text1') }}{{ $t('buyGpuServer.text2') }}</div>
      <!-- <div class="cont_desc">{{ $t('buyGpuServer.text2') }}</div> -->
    </div>
    <div class="content2">
      <div class="cont_title">{{ $t('home.cont3.title') }}</div>
      <div class="cont_desc">{{ $t('home.cont3.text') }}</div>
      <div class="ecologyList">
        <div class="ecologyLi delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../../assets/home/cont3_img1.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title1') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text1') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.deeplink.cloud/"><span><img src="../../assets/home/deeplink.png" alt=""></span> {{ $t('home.cont3.btn1_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../../assets/home/decentral_gpt.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title2') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text2') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.decentralgpt.org/"><span><img src="../../assets/home/decentral_gpt.png" alt=""></span> {{ $t('home.cont3.btn2_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../../assets/home/superimage.webp" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title3') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text3') }}</div>
          <div class="ecology_btns">
            <!-- <div class="btn1">{{ $t('home.cont3.btn3_1') }}</div> -->
            <a class="btn1" target="_blank" href="https://www.superimage.ai/"><span><img src="../../assets/home/superimage.webp" alt=""></span> {{ $t('home.cont3.btn3_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../../assets/home/drcpad.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title4') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text4') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.drcpad.io/"><span><img src="../../assets/home/drcpad.png" alt=""></span> {{ $t('home.cont3.btn4_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../../assets/home/cont3_img5.jpg" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title5') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text5') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.hycons.cloud"><span><img src="../../assets/home/hycons.png" alt=""></span> {{ $t('home.cont3.btn5_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../../assets/home/subscan.webp" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title6') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text6') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://dbc.subscan.io/"><span><img src="../../assets/home/subscan.webp" alt=""></span> {{ $t('home.cont3.btn6_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../../assets/home/dbcwallet.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title7') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text7') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://test.dbcscan.io/"><span><img src="../../assets/home/dbcwallet.png" alt=""></span> {{ $t('home.cont3.btn7_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../../assets/home/cont3_img9.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title9') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text9') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://xaiagent.io/"><span><img src="../../assets/home/cont3_img9.png" alt=""></span> {{ $t('home.cont3.btn9_1') }}</a>
          </div>
        </div>
        <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
          <div class="ecologyIcon"><img src="../../assets/home/dbcwallet.png" alt=""></div>
          <div class="ecology_title">{{ $t('home.cont3.title8') }}</div>
          <div class="ecology_text">{{ $t('home.cont3.text8') }}</div>
          <div class="ecology_btns">
            <a class="btn1" target="_blank" href="https://www.dbcwallet.io/"><span><img src="../../assets/home/dbcwallet.png" alt=""></span> {{ $t('home.cont3.btn8_1') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'buyGpuServer',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.buyGpuServer {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1200px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    margin: auto;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 80px;
    box-sizing: border-box;
  }
  .content2 {
    position: relative;
    width: 100%;
    // height: 1010px;
    padding: 80px 0 100px;
    box-sizing: border-box;
    .cont_title {
      color: #fff;
      margin-bottom: 12px;
    }
    .ecologyList {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      margin: 100px auto 0;
      justify-content: space-between;
      .ecologyLi {
        width: 384px;
        // height: 270px;
        position: relative;
        margin-bottom: 80px;
        padding: 60px 24px 70px;
        box-sizing: border-box;
        border: 2px solid #FFF;
        backdrop-filter: blur(12.5px);
        transition: all 1s;
        box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        &:hover {
          border: 2px solid ;
          border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
          backdrop-filter: blur(12.5px);
          box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
          background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(148, 148, 148, 0.40) 136.58%);
        }
        .ecologyIcon {
          position: absolute;
          width: 96px;
          height: 96px;
          top: -50px;
          left: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .ecology_title {
          color: #FFF;
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 15px;
          line-height: normal;
        }
        .ecology_text {
          font-size: 16px;
          color: #899AA9;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 15px;
          word-wrap: break-word;
        }
        .ecology_btns {
          position: absolute;
          bottom: 24px;
          width: 336px;
          height: 43px;
          display: flex;
          color: #FFF;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          align-items: center;
          justify-content: space-between;
          .btn {
            width: 160px;
            height: 43px;
            color: #FFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            border: 1px solid #FFF;
            span {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
          .btn1 {
            width: 100%;
            height: 43px;
            display: flex;
            color: #FFF;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            border: 1px solid #FFF;
            span {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
}

</style>
